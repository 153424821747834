import { template as template_8ceb61c9823846f286a446ed0de3ccce } from "@ember/template-compiler";
const FKControlMenuContainer = template_8ceb61c9823846f286a446ed0de3ccce(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
