import { template as template_c9b69c1b3fd54d2c957bc583a907685d } from "@ember/template-compiler";
const FKText = template_c9b69c1b3fd54d2c957bc583a907685d(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
