import { template as template_f57a66287a244153b0bed129efb8c7de } from "@ember/template-compiler";
const FKLabel = template_f57a66287a244153b0bed129efb8c7de(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
